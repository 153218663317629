import shoe1Url from '../../../assets/shoe1.png'
import shoe2Url from '../../../assets/shoe2.png'
import shoe3Url from '../../../assets/shoe3.png'
import shoe4Url from '../../../assets/shoe4.png'
import shoe5Url from '../../../assets/shoe5.png'
import shoe6Url from '../../../assets/shoe6.png'
import shoe7Url from '../../../assets/shoe7.png'
import shoe8Url from '../../../assets/shoe8.png'

import thursdayBootsUrl from '../../../assets/thursday_boots.png'
import ministryOfSupplyUrl from '../../../assets/ministry_of_supply.png'
import paravelUrl from '../../../assets/paravel.png'
import adayUrl from '../../../assets/aday.png'
import mackageUrl from '../../../assets/mackage.png'
import aesopUrl from '../../../assets/aesop.png'
import wandpUrl from '../../../assets/wandp.png'
import aetherApparelUrl from '../../../assets/aether_apparel.png'

import { PictureTile } from '../../../components/PictureTile'

import './TileImages.css'

export const TileShoe1 = () => {
  return (
    <PictureTile labelText="Floaty shoes">
      <div className="TileImage">
        <img src={shoe1Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe2 = () => {
  return (
    <PictureTile labelText="Whitey shoes">
      <div className="TileImage">
        <img src={shoe2Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe3 = () => {
  return (
    <PictureTile labelText="Dressy shoes">
      <div className="TileImage">
        <img src={shoe3Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe4 = () => {
  return (
    <PictureTile labelText="Heely shoes">
      <div className="TileImage">
        <img src={shoe4Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe5 = () => {
  return (
    <PictureTile labelText="Painty shoes">
      <div className="TileImage">
        <img src={shoe5Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe6 = () => {
  return (
    <PictureTile labelText="Fancy shoes">
      <div className="TileImage">
        <img src={shoe6Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe7 = () => {
  return (
    <PictureTile labelText="Twisty shoes">
      <div className="TileImage">
        <img src={shoe7Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const TileShoe8 = () => {
  return (
    <PictureTile labelText="Yummy shoes">
      <div className="TileImage">
        <img src={shoe8Url} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const ThursdayBootsTile = () => {
  return (
    <PictureTile labelText="thursday boots">
      <div className="TileImage">
        <img src={thursdayBootsUrl} alt="Shoe Example"></img>
      </div>
    </PictureTile>
  )
}

export const MinistryOfSupplyTile = () => {
  return (
    <PictureTile labelText="ministry of supply">
      <div className="TileImage">
        <img src={ministryOfSupplyUrl} alt="Ministry of Supply"></img>
      </div>
    </PictureTile>
  )
}

export const ParavelTile = () => {
  return (
    <PictureTile labelText="paravel luggage">
      <div className="TileImage">
        <img src={paravelUrl} alt="Paravel Luggage"></img>
      </div>
    </PictureTile>
  )
}

export const AdayTile = () => {
  return (
    <PictureTile labelText="aday">
      <div className="TileImage">
        <img src={adayUrl} alt="Aday"></img>
      </div>
    </PictureTile>
  )
}

export const MackageTile = () => {
  return (
    <PictureTile labelText="mackage">
      <div className="TileImage">
        <img src={mackageUrl} alt="Mackage"></img>
      </div>
    </PictureTile>
  )
}

export const AesopTile = () => {
  return (
    <PictureTile labelText="aesop">
      <div className="TileImage">
        <img src={aesopUrl} alt="Aesop"></img>
      </div>
    </PictureTile>
  )
}

export const WandpTile = () => {
  return (
    <PictureTile labelText="w&p design">
      <div className="TileImage">
        <img src={wandpUrl} alt="W&P Design"></img>
      </div>
    </PictureTile>
  )
}

export const AetherApparelTile = () => {
  return (
    <PictureTile labelText="aether apparel">
      <div className="TileImage">
        <img src={aetherApparelUrl} alt="Aether Apparel"></img>
      </div>
    </PictureTile>
  )
}
