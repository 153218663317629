import { TilesContainer } from '../../components/TilesContainer'
import { TilePages } from './tiles/TilePages'
import { TileEarnings } from './tiles/TileEarnings'
import {
  MinistryOfSupplyTile,
  ParavelTile,
  AdayTile,
  MackageTile,
  ThursdayBootsTile,
  AesopTile,
  WandpTile,
  AetherApparelTile,
} from './tiles/TileImages'
import { TileImpressions } from './tiles/TileImpressions'
import { TileShares } from './tiles/TileShares'
import useOrientation from '../../hooks/useOrientation'

import './TileSection.css'

export const TilesSection = () => {
  const SIZE_BREAKPOINT = 640
  const { horizontal } = useOrientation()
  const { innerWidth } = window

  return (
    <div
      className={`TilesContainer lg:w-1/2 w-full lg:mb-0 lg:ml-8 mb-8 overflow-hidden ${
        horizontal ? 'horizontal' : 'vertical'
      }`}
    >
      <TilesContainer
        tileArray={[
          <TilePages />,
          <MackageTile />,
          <TileEarnings />,
          <ThursdayBootsTile />,
          <AdayTile />,
          <TileImpressions />,
          <MinistryOfSupplyTile />,
          <TileShares />,
          <ParavelTile />,
          <WandpTile />,
          <AetherApparelTile />,
          <AesopTile />,
        ]}
        rowSize={innerWidth > SIZE_BREAKPOINT ? 3 : 2}
      />
    </div>
  )
}
