import React from 'react'
import './Tile.css'

export const Tile = ({
  children,
  color,
}: {
  children: React.ReactNode
  color: string
}) => {
  let style: Record<string, any> = {
    backgroundColor: color,
  }

  return (
    <div className="Tile md:p-4 p-2 rounded-xl" style={style}>
      <div className="TileContent">{children}</div>
    </div>
  )
}
