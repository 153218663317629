import './TilesContainer.css'
import useOrientation from '../../hooks/useOrientation'

import { Tile } from '../Tile'
import { PictureTile } from '../PictureTile'

type TileSizes = {
  tile_lg: number
  tile_md: number
}

const SIZES: Record<string, TileSizes> = {
  lg: {
    tile_lg: 16,
    tile_md: 12,
  },
  xl: {
    tile_lg: 12,
    tile_md: 10,
  },
}

const getSize = (tileSize: keyof TileSizes): number => {
  if (window.innerWidth > 1280) {
    return SIZES['xl'][tileSize]
  } else {
    return SIZES['lg'][tileSize]
  }
}

export const TilesContainer = ({
  tileArray,
  rowSize = 3,
}: {
  tileArray: Array<React.ReactNode>
  rowSize?: number
}) => {
  const colSize =
    tileArray.length % rowSize === 0
      ? tileArray.length / rowSize
      : Math.floor(tileArray.length / rowSize) + 1

  return (
    <div className="HorizontalContainer">
      {[...Array(rowSize).keys()].map((i) => (
        <div className="VerticalContainer" key={`TileCol${i}`}>
          {[...Array(colSize).keys()].map((row) => {
            if (i * colSize + row < tileArray.length) {
              return (
                <div
                  className="ChildTile lg:mt-8 mt-4"
                  key={`Tile${i * colSize + row}`}
                >
                  {tileArray[i * colSize + row]}
                </div>
              )
            }
          })}
        </div>
      ))}
    </div>
  )
}
