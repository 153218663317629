export const MESSAGES = {
  'button.join_waitlist': 'Join waitlist',
  'button.share': 'Share',
  'landing_page.header.referer': '${NAME} wants you to check out Rippl!',
  'landing_page.header.title': 'Get paid to share.',
  'landing_page.header.description':
    'Create customizable pages to share with your followers and earn money for impressions and shares!',
  'landing_page.header.disclaimer':
    'Pages aren\'t fully customizable yet, but we’ll create a cool demo page for you to check out.',
  'landing_page.share.header.title': 'Share your page.',
  'landing_page.share.header.description':
    "We'll email you when you can build more powerful, shareable pages!",
  'landing_page.share.header.disclaimer':
    'Pages aren\'t fully customizable yet, but here’s a preview of earnings you can make.',
  'logo.placeholder': 'rippl',
  'placeholder.your_email': 'Your email here.',
  'tile.impressions.unit': 'impressions',
  'tile.potential_earning.no_referer.title': 'Earn with each impression:',
  'tile.potential_earning.title': 'Potential earnings:',
  'tile.shares.unit': 'shares',
  'tile.reached.unit': 'reached',
  'tile.waitlist.description': 'pages ahead of you',
}
