export async function create_page(
  email: string,
  referrer_id?: string
): Promise<any> {
  const server_port = process.env.REACT_APP_SERVER_PORT ? `:${process.env.REACT_APP_SERVER_PORT}`: ''
  const resp = await fetch(
    `${window.location.protocol}//${window.location.hostname + server_port}/api/v1/get_or_create_page`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        referrer_id,
      }),
    }
  )

  return resp.json()
}
