import { MESSAGES } from './messages'
import { lookupMessage } from './lookupMessage'

export { lookupMessage } from './lookupMessage'

export const Message = ({
  className,
  id,
  data,
}: {
  className?: string
  id: keyof typeof MESSAGES
  data?: { [key: string]: string }
}) => {
  return <div className={className}>{lookupMessage(id, data)}</div>
}
