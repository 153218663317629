import { MESSAGES } from './messages'

export const lookupMessage = (
  id: keyof typeof MESSAGES,
  data?: { [key: string]: string }
) => {
  let message = MESSAGES[id]
  if (data != null) {
    Object.keys(data).forEach((key: string) => {
      message = message.replace(`\$\{${key}\}`, data[key])
    })
  }
  return message
}
