import React from 'react'
import classNames from 'classnames'
import './PictureTile.css'

export const PictureTile = ({
  children,
  labelText,
}: {
  children: React.ReactNode
  labelText?: string
}) => {
  return (
    <div className="PictureTile rounded-xl">
      <div className="PictureTileContent">{children}</div>
      {labelText && (
        <div className="PictureTileLabelText pl-2 rounded-b-xl">
          <div className="text-sm font-bold" style={{ color: '#fff' }}>
            {labelText}
          </div>
        </div>
      )}
    </div>
  )
}
