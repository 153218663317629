import { Message } from '../../components/Message'
import { ShareSection } from '../../section/ShareSection'
import './SharePage.css'

export const SharePage = () => {
  return (
    <div className="ShareContainer lg:h-screen lg:w-1/2 lg:mt-0 mt-36 ml-8">
      <form className="WaitlistForm">
        <Message
          className="sm:text-7xl text-5xl font-bold sm:mt-8 mt-4"
          id="landing_page.share.header.title"
        />
        <Message
          className="sm:text-xl text-md font-bold sm:mt-8 mt-4 leading-normal"
          id="landing_page.share.header.description"
        />
        <div className="mt-8">
          <ShareSection />
        </div>
        <Message
          className="mt-4 text-sm"
          id="landing_page.share.header.disclaimer"
        />
      </form>
    </div>
  )
}
