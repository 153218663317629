import './App.css'

import { useEffect, useState, useContext } from 'react'
import { Message } from './components/Message'
import { PageContext, PageContextProvider } from './contexts/PageContext'
import { useNavigate } from 'react-router-dom'

import { useParams } from 'react-router-dom'
import { WelcomePage } from './page/WelcomePage'
import { SharePage } from './page/SharePage'
import { TilesSection } from './section/TilesSection'
import { getLocalStorageToken } from './helpers/localStorage'

import { get_page } from './api'

export const AppContent = () => {
  const navigate = useNavigate()
  const { pageid } = useParams()
  const { setPage } = useContext(PageContext)
  const [loading, setLoading] = useState(true)
  const [isOwner, setIsOwner] = useState(false)

  useEffect(() => {
    get_page(pageid).then((resp: any) => {
      if (resp.error == null) {
        setPage?.(resp)
      }
      setLoading(false)
    })
  }, [pageid])

  useEffect(() => {
    const localStorageToken = getLocalStorageToken()

    if (localStorageToken === pageid) {
      navigate(`/${localStorageToken}`)
      setIsOwner(true)
    }
  }, [pageid, navigate])

  if (loading) {
    return <></>
  }

  return (
    <div className="AppContent lg:flex-row flex-col xl:ml-16">
      {!isOwner && <WelcomePage />}
      {isOwner && <SharePage />}
      <TilesSection />
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <div className="AppLogo lg:fixed text-3xl font-bold mt-8 ml-8">
        <Message id="logo.placeholder" />
      </div>
      <PageContextProvider>
        <AppContent />
      </PageContextProvider>
    </div>
  )
}

export default App
