import React, { createContext, useState } from 'react'

export type Page = {
  id: string
  short_id: string
  email: string
  earnings: number
  impressions: number
  waitlist_spot: number
  recursive_referrals: number
}

type PageContextType = {
  page?: Page
  setPage: (page: Page) => void
}

export const PageContext = createContext<PageContextType>({
  page: undefined,
  setPage: () => {},
})

export const PageContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [page, setPage] = useState<Page | undefined>(undefined)

  return (
    <PageContext.Provider
      value={{
        page,
        setPage: (p) => setPage(p),
      }}
    >
      {children}
    </PageContext.Provider>
  )
}
