import { useContext } from 'react'

import { Message } from '../../../components/Message'
import { PageContext } from '../../../contexts/PageContext'
import { Tile } from '../../../components/Tile'

import './TileImpressions.css'

export const TileImpressions = () => {
  const { page } = useContext(PageContext)

  return (
    <Tile color="black">
      <div className="TileImpressions">
        <div className="lg:text-4xl text-5xl font-bold">
          {page != null ? page.impressions : 56}
        </div>
        <div className="font-bold">
          <Message id="tile.impressions.unit" />
        </div>
      </div>
    </Tile>
  )
}
