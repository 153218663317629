import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { create_page } from '../../api'
import { isRootPage } from '../../helpers'
import { Message, lookupMessage } from '../../components/Message'
import { setLocalStorageToken } from '../../helpers/localStorage'
import { PageContext } from '../../contexts/PageContext'

import './WelcomePage.css'

export const WelcomePage = () => {
  const [email, setEmail] = useState<string>('')
  const navigate = useNavigate()
  const { page } = useContext(PageContext)

  return (
    // TODO: Avoid hardcoded w-3/5 width
    <div className="CTAContainer lg:h-screen lg:w-1/2 lg:mt-0 mt-36 mx-8">
      <form
        className="WaitlistForm"
        onSubmit={async (e) => {
          e.preventDefault()
          create_page(email, page?.short_id).then((resp: any) => {
            setLocalStorageToken(resp.short_id)
            navigate(`/${resp.short_id}`)
          })
        }}
      >
        {page != null && !isRootPage(page) && (
          <Message
            className="sm:text-xl text-md"
            id="landing_page.header.referer"
            data={{ NAME: page?.email }}
          />
        )}
        <Message
          className="sm:text-7xl text-5xl font-bold sm:mt-8 mt-4"
          id="landing_page.header.title"
        />
        <Message
          className="sm:text-xl text-md font-bold sm:mt-8 mt-4 leading-normal"
          id="landing_page.header.description"
        />
        <div className="mt-8">
          <input
            className="EmailInput text-sm md:w-3/5 w-full"
            placeholder={lookupMessage('placeholder.your_email')}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
        </div>
        <button className="JoinButton mt-4" type="submit">
          <Message className="text-sm" id="button.join_waitlist" />
        </button>
        <Message className="mt-4 text-sm" id="landing_page.header.disclaimer" />
      </form>
    </div>
  )
}
