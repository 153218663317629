import { useContext } from 'react'

import { Message } from '../../../components/Message'
import { PageContext } from '../../../contexts/PageContext'
import { Tile } from '../../../components/Tile'

import './TileShares.css'

export const TileShares = () => {
  const { page } = useContext(PageContext)

  return (
    <Tile color="white">
      <div className="TileShares">
        <div className="lg:text-4xl text-5xl font-bold">
          {page != null ? page.recursive_referrals : 12}
        </div>
        <div className="font-bold">
          <Message id="tile.shares.unit" />
        </div>
      </div>
    </Tile>
  )
}
