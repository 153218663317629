import { useContext } from 'react'

import { Message } from '../../../components/Message'
import { PageContext } from '../../../contexts/PageContext'
import { Tile } from '../../../components/Tile'
import { isRootPage } from '../../../helpers'

import './TilePages.css'

export const TilePages = () => {
  const { page } = useContext(PageContext)

  const getWaitlistSpot = () => {
    if (isRootPage(page)) {
      // For root page, number of total pages to wait for is # of shares + 1 because
      // waitlist_spot starts at 1.
      return (page?.recursive_referrals || 0) + 1
    } else {
      return page?.waitlist_spot
    }
  }

  return (
    <Tile color="white">
      <div className="TilePages">
        <div>
          <span className="Accented font-bold text-3xl">{getWaitlistSpot()}</span>
          <Message
            className="lg:text-lg text-2xl"
            id="tile.waitlist.description"
          />
        </div>
      </div>
    </Tile>
  )
}
