import { useContext } from 'react'

import { Message } from '../../../components/Message'
import { PageContext } from '../../../contexts/PageContext'
import { Tile } from '../../../components/Tile'
import { isRootPage } from '../../../helpers'

import './TileEarnings.css'

export const TileEarnings = () => {
  const { page } = useContext(PageContext)

  return (
    <Tile color="black">
      <div className="TileEarnings">
        <div className="EarningsHeader">
          <Message
            className="text-xl lg:text-sm font-bold"
            id={
              isRootPage(page)
                ? 'tile.potential_earning.no_referer.title'
                : 'tile.potential_earning.title'
            }
          />
        </div>
        <div className="EarningsFigure lg:text-4xl text-4xl font-bold mt-2">
          {page != null
            ? page.earnings.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : '$4.76'}
        </div>
      </div>
    </Tile>
  )
}
