import { useState } from 'react'
import { Message } from '../../components/Message'
import './ShareSection.css'

export const ShareSection = () => {
  const [hasCopied, setHasCopied] = useState(false)
  const referralLink = document.location.href

  const writeToClipboard = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigator.clipboard.writeText(referralLink)
    setHasCopied(true)
  }

  const onClick = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Your title',
        text: 'Your text',
        url: 'Your url to share',
      })
    }
  }

  return (
    <div className="ShareSection sm:flex-row sm:items-center flex-col">
      <div className="ReferralLink flex-1">
        <div className="ReferralLinkText">{referralLink}</div>
        {navigator.clipboard && (
          <div>
            {!hasCopied && (
              <button
                className="CopyButton md:text-sm text-xs"
                onClick={writeToClipboard}
              >
                Copy
              </button>
            )}
            {hasCopied && (
              <button
                className="CopyButtonCopied md:text-sm text-xs"
                onClick={writeToClipboard}
              >
                Copied!
              </button>
            )}
          </div>
        )}
      </div>
      {navigator.share !== undefined && (
        <button
          className="ShareButton sm:ml-4 sm:mt-0 mt-4"
          onClick={onClick}
          type="submit"
        >
          <Message className="md:text-sm text-xs" id="button.share" />
        </button>
      )}
    </div>
  )
}
