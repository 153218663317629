// https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
import { useState, useEffect } from 'react'

function getOrientation() {
  const { innerWidth: width } = window
  return {
    horizontal: width >= 1024,
    vertical: width < 1024,
  }
}

export default function useOrientation() {
  const [orientation, setOrientation] = useState(getOrientation())

  useEffect(() => {
    function handleResize() {
      setOrientation(getOrientation())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return orientation
}
